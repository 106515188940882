<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br/>
        <br/>
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Keyword List</strong>
            </div>
            <div class="text-muted">Edit keyword list information</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>IP list's name.</span>
                  </template>
                  <a-input type="text" placeholder="Keyword List Name" v-decorator="[ 'name', { initialValue: kwListData.Name,
                rules: [
                    { required: true, message: 'Name is required.' },
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                          :class="'btn btn-'+(kwListData.Status === 0 ? 'danger' : 'primary')+' px-5 ml-2'"
                          @click.prevent="toggleKeywordListStatus">
                    {{ kwListData.Status == 0 ? 'Deactivate' : 'Activate' }} Keyword List
                  </button>
                  <button type="button" class="btn px-5 ml-2" @click="$router.push({ name: 'xml-kwlist' })">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'

export default {
  components: {},
  computed: {
    kwListData: function () {
      let kwListData = this.$store.getters['kwlist/getKeywordListData'](this.$route.params.id)
      if (typeof kwListData === 'undefined') {
        this.$store.dispatch('kwlist/LOAD_KWLISTS')
        kwListData = {}
      }
      return kwListData
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('kwlist/UPDATE_KWLIST', {
            id: this.kwListData.Id,
            payload: values,
            callback: function () {
              this.$router.push({ name: 'xml-kwlist' })
            }.bind(this),
          })
        }
      })
    },
    toggleKeywordListStatus() {
      const status = this.kwListData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Keyword list status?',
        content: h => <div>{status} Keyword list <strong>{this.kwListData.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = this.kwListData.Status === 0 ? 1 : 0
          this.$store.dispatch('kwlist/CHANGE_STATUS', {
            id: this.kwListData.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
  },
  created() {
    this.$store.dispatch('kwlist/LOAD_KWLISTS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
